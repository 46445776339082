import { useAppSelector, useAppDispatch } from "config/hooks"
import React, { useEffect, useState } from "react"
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from "redux"
import { actionCreators } from 'store'

import Popup from "components/commonComponents/popup/Popup"
import DashboardDefault from 'components/dashboard/default/Default'
import ProceedDemoScreen from 'components/endScreens/ProceedDemoScreen'
import IntroComponentDefault from 'components/intro/introComponentDefault'
import DemoEndScreen from 'components/endScreens/DemoEndScreen'
import { getString } from "utils";

/**
 * This class will decide which dashboard component will be rendered
 */
const Demo: React.FC = () => {

	// Router useHistory initialisation 
	const history = useHistory();

	/* {show walkthrough for sure then dashboard demo} */
	const showLoginPopup = useAppSelector(state => state.showLoginPopup)
	const isDemoEnabled = useAppSelector(state => state.initUser.isDemoEnabled)
	const isDemoCompleted = useAppSelector(state => state.initUser.isDemoCompleted)
	const isDemoUser = useAppSelector(state => state.initUser.isDemoUser)
	const userCurrentDay = useAppSelector(state => state.initUser.userCurrentDay)
	const timer = useAppSelector(state => state.globalData.timer)
	const simDays = useAppSelector(state => state.storylineData.simDays)
	const month = useAppSelector(state => state.globalData.month)

	const [showProceedDemo, setShowProceedDemo] = useState(true)
	const [showComponent, setShowComponent] = useState(false)
	
	const dispatch = useAppDispatch()
	const { startDemoMode, resetDataFetched, setDemoCompleted, exitDemoMode, setReportData } = bindActionCreators(actionCreators, dispatch)

	useEffect(() => {

		if (isDemoEnabled == 1 && isDemoCompleted == 1) {
			// show ContinueDemo screen on /demo
			setShowProceedDemo(true) 
			setShowComponent(true)
		}
		else if ((isDemoEnabled == 1 && isDemoCompleted == 2) || isDemoUser == 0) {
			// redirect to Intro -- Meet your team
			history.push('/intro')
		}
		else {
			setShowProceedDemo(false)
			setShowComponent(true)
		}
	}, [])

	const handleProceedDemo = async (selectedOption: number) => {

		if (selectedOption == 0) {
			// startDemo
			startDemoMode()
			// resetDataFetched()
			setShowProceedDemo(false)
		}
		else {
			// skip to simulation
			// setDemoCompletedFlag and redirect to /dashboard
			setDemoCompleted()
			resetDataFetched()
			history.push('/dashboard')
		}
	}

	const handleExitDemo = async () => {
		setReportData(month)
		exitDemoMode()
		resetDataFetched()
		// the demo user is not updated, the normal user has game status and scores, just the last api is updated wrong
		// from session wrapper of php. 

	}


	return (
		<>
			{!showComponent ? <IntroComponentDefault showIntroBody={false} />
				:
				<>
					{showProceedDemo ?
						<IntroComponentDefault showIntroBody={false}>
							<ProceedDemoScreen handleProceedDemo={handleProceedDemo} />
						</IntroComponentDefault>
						:
						<>
							<DashboardDefault />
							{userCurrentDay < simDays && timer != 0 ? <DemoEndScreen isDemoOngoing={userCurrentDay < simDays && timer != 0} gameStatus={1} handleExitDemo={handleExitDemo} /> : null}
							{showLoginPopup ?
								<>
									<Popup>
										<div className="phead">
											<p style={{ display: "inherit" }}>
												<img src={require(`assets/images/Meeting.svg`).default} style={{ width: "3vw" }} />
												<span>&nbsp;{getString("label_logout")}</span>
											</p>
										</div>
										<div className="curved-header"></div>
										<div className="pbody mty-actor" style={{ marginRight: "0", width: "41vw", height: "31vh", fontSize: "2vh", lineHeight: "2.4vh", justifyContent: "center", alignItems: "center", borderRadius: '0', borderBottomLeftRadius: '0.7vw', borderBottomRightRadius: '0.7vw' }}>
											<p dangerouslySetInnerHTML={{ __html: getString("label_login_again") }} style={{ color: "#083880", fontSize: "2.6vh" }}></p>
											<div className="lbuttons">
												<button className="logout-btn" onClick={() => window.location.assign(`${process.env.REACT_APP_LOGIN_URL}`)}>{getString("label_login_again_heading")}</button>
											</div>
										</div>
									</Popup>
								</>
								: null}
						</>
					}
				</>
			}
		</>
	);
}

export default Demo;
